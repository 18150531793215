import React from "react"
import { jsx, css } from "@emotion/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCoffee,
  faAddressBook,
  faAirFreshener,
  faAmbulance,
  faAtom,
  faBus,
  faStopwatch,
  faWrench,
  faCircle,
  faSmile,
} from "@fortawesome/free-solid-svg-icons"

export default function BenefitsSection(props) {
  return (
    <section css={benefits}>
      <div css={benefitsSection}>
        <h2 css={benefitTitle}>Kodėl Kaune rinkits mus?</h2>
        <div css={benefitsArrangement}>
          <div css={benefitCard}>
            <span style={{ display: "inline-block", position: "relative" }}>
              <FontAwesomeIcon
                textAnchor="middle"
                alignmentBaseline="middle"
                css={benefitIcon}
                icon={faSmile}
              />

              <FontAwesomeIcon
                textAnchor="middle"
                alignmentBaseline="middle"
                css={benefitIconBackground}
                icon={faCircle}
              />
            </span>
            <h4 css={benefitCardTitle}>Rinkitės mus</h4>
            <p css={benefitCardText}>
              Dirbame efektyviai. Skiriame visą dėmesį ir turimą įrangą, kad
              pasirūpintume jūsų automobiliu.
            </p>
          </div>
          <div css={benefitCard}>
            <span style={{ display: "inline-block", position: "relative" }}>
              <FontAwesomeIcon
                textAnchor="middle"
                alignmentBaseline="middle"
                css={benefitIcon}
                icon={faWrench}
              />

              <FontAwesomeIcon
                textAnchor="middle"
                alignmentBaseline="middle"
                css={benefitIconBackground}
                icon={faCircle}
              />
            </span>
            <h4 css={benefitCardTitle}>Nauja įranga</h4>
            <p css={benefitCardText}>
              Mūsų autoservise naudojama nauja ir moderni diagnostikos ir
              remonto įranga, todėl galime pašalinti ir sudėtingus gedimus.
            </p>
          </div>
          <div css={benefitCard}>
            <span style={{ display: "inline-block", position: "relative" }}>
              <FontAwesomeIcon
                textAnchor="middle"
                alignmentBaseline="middle"
                css={benefitIcon}
                icon={faStopwatch}
              />

              <FontAwesomeIcon
                textAnchor="middle"
                alignmentBaseline="middle"
                css={benefitIconBackground}
                icon={faCircle}
              />
            </span>
            <h4 css={benefitCardTitle}>Greitas aptarnavimas</h4>
            <p css={benefitCardText}>
              Autoserviso paslaugas suteiksime greitai – išsiaiškinsime problemą
              ir pateiksime jos sprendimą gedimams pašalinti.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
const benefitsSection = css`
  padding: 20px 0px 60px 0px;
  margin: 0 auto;
  max-width: 1200px;
`
const benefits = css`
  background-color: #f8f9fa;
`

const benefitTitle = css`
  text-align: center;
  font-size: 37px;
  font-weight: 700;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
`

const benefitCardTitle = css`
  font-size: 24px;
  text-align: center;
  margin: 0 0;
  font-weight: 700;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
`

const benefitCardText = css`
  text-align: center;
  font-size: 16px;
  color: #6c757d;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`

const benefitCard = css`
  width: 300px;
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
  align-items: center;
`

const benefitsArrangement = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const benefitIconBackground = css`
  color: #feb836;
  font-size: 124px;
`

const benefitIcon = css`
  color: #fff;
  font-size: 64px;
  left: 30px;
  bottom: 30px;
  position: absolute;
`

const benefitIconStack = css`
  display: block;
  height: 2em;

  position: relative;

  font-size: 4em;
`
