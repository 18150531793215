import React from "react"
import Layout from "../components/layout/Layout"
import { Helmet } from "react-helmet"
import BenefitsSection from "../components/landing/benefits"
import styles from "../styles/text-section.module.scss"
import ContactForm from "../components/general/contactForm/kontaktuForma"

export default function ApieMus() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Lengvųjų automobilių remontas Kaune kokybiškai ir greitai.
        </title>
        <meta
          name="description"
          content="Kokybiškos automobilių remonto paslaugos: variklio remontas, variklio diagnostika, važiuoklės remontas, padangų keitimas, padangų remontas, ratų montavimas, ratų balansavimas, tepalų keitimas."
        ></meta>
      </Helmet>

      <section className={`${styles.textSection} container`}>
        <h1>Kodėl rinktis mus?</h1>
        <p>
          Kodėl mes? Nes didžiausia mūsų vertybė yra skaidriai, kokybiškai ir
          laiku atlikti automobilių remonto darbai. Tikime, kad tik pasitikėjimu
          pagrįstas bendradarbiavimas gali užtikrinti, kad klientas liks
          patenkintas ir savo automobilį eksploatuos dar ne vienerius metus.
        </p>
        <p>
          Jei jūsų transporto priemonei reikia techninės priežiūros ar remonto,
          mes pasistengsime suteikti jums visas reikalingas paslaugas be
          papildomų rūpesčių ir kuo greičiau grąžinti jus į kelią. Mes
          didžiuojamės būdami jūsų patikimu servisu, kur su jumis elgiamasi kaip
          su geru draugu.
        </p>
        <p>
          Kartu su patyrusių specialistų komanda stengiamės pelnyti jūsų
          pasitikėjimą, būdami sąžiningi viskuo, ką darome. Mes niekada
          nebandysime pasiūlyti nereikalingos paslaugos ar taisyti jūsų
          transporto priemonės prieš tai darbų nesuderinę su jumis.
        </p>
        <p>
          Esame pasiryžę suteikti jums sąžiningą ir skaidrų automobilių
          aptarnavimą. Mes norime, kad mūsų klientai suprastų, kas yra negerai
          su jų transporto priemone, kokios yra jų remonto galimybės, ir
          pasitikėtų mūsų teikiamomis paslaugomis. Sąžiningumas – mūsų pažadas
          jums.
        </p>
      </section>
      <ContactForm />
      <BenefitsSection />
    </Layout>
  )
}
